/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout, Link } from '@components';

interface PartnersProps {
    data: any;
    pageContext: { locale: string };
}

const Partners: React.FC<PartnersProps> = ({ data, pageContext: { locale } }) => {
    const urlRegex = /[a-zA-Z0-9-]{2,50}(\.[a-zA-Z0-9-]{2,50})+([/][-a-zA-Z0-9@:%_+.~#?&=]+)*/;

    return (
        <Layout lang={locale} path="/partners">
            <h1 className="font-bold text-center contentBlock">
                {locale == 'en' ? 'Partners' : 'Partneři'}
            </h1>
            <div className="rounded fuckmorphism bg-bgcolor/40">
                {data.allStrapiPartnerType.nodes.map(({ partners, Title }) => {
                    return (
                        partners.length > 0 && (
                            <div>
                                <h3 className="font-normal text-center contentBlock">{Title}</h3>
                                {partners
                                    .sort((a, b) => a.Index - b.Index)
                                    .map(({ Logo, Link: href, Name }, index: number) => {
                                        return (
                                            <Link className="partner" key={index} to={href}>
                                                <div className="md:w-1/2">
                                                    <h4>{Name}</h4>
                                                    <div className="text-xs font-bold text-gray-400 md:text-base">
                                                        {href.match(urlRegex)[0]}
                                                    </div>
                                                </div>
                                                <div>
                                                    {Logo?.localFile && (
                                                        <GatsbyImage
                                                            className="left-0 object-center w-32 h-auto my-auto max-h-16 md:w-40 hover:filter-none "
                                                            image={
                                                                Logo.localFile.childImageSharp
                                                                    .gatsbyImageData
                                                            }
                                                            alt={Link}
                                                            imgClassName="md:object-right object-contain"
                                                            imgStyle={{ objectFit: 'contain' }}
                                                        />
                                                    )}
                                                </div>
                                            </Link>
                                        );
                                    })}
                            </div>
                        )
                    );
                })}
            </div>
        </Layout>
    );
};

export default Partners;

export const query = graphql`
    query AllPartners($locale: String!) {
        allStrapiPartnerType(sort: { fields: Index }, filter: { locale: { eq: $locale } }) {
            nodes {
                partners {
                    Logo {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    blurredOptions: { width: 120 }
                                )
                            }
                        }
                    }
                    Link
                    Name
                    Index
                }
                Type
                Title
            }
        }
    }
`;
